import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card';
import { Row, Col, Dropdown, Menu, Button, Pagination } from 'antd';
import '../pages/styles/refugee_resources.css'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import app_free from '../images/app_free.jpeg'
import fraud from '../images/fraud.png'
import app_store from '../images/app_store.png'
import desktop from '../images/desktop.png'
import dna from '../images/dna.jpeg'
import learn_eng from '../images/learn_eng.jpeg'
import settle from '../images/settle.png'
import p3 from '../images/p3.jpeg'
import patient from '../images/patient.png'
import play_store from '../images/google_play.png'
import spouse from '../images/spouse.jpeg'
import us_reset_process from '../images/us_reset_process.png'
import wat_can from '../images/wat_can.jpeg'
import dos from '../images/dos.jpeg'
import pay from '../images/pay.jpeg'
import prep from '../images/prep.jpeg'
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { VideoScrollTemplate } from '../components/video_scroll_template';
import { MessagesTemplate } from '../components/messages_template'
import { Link } from 'react-router-dom';
import data from '../components/fixtures';
//pdfs
//USRAP is Free
import free_amharic_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Amharic.pdf'
import free_arabic_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Arabic.pdf'
import free_english_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_English.pdf'
import free_francaise_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_French.pdf'
import free_kinyarwanda_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Kinyarwanda.pdf'
import free_kirundi_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Kirundi.pdf'
import free_kiswahili_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Kiswahili.pdf'
import free_oromo_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Oromo.pdf'
import free_somali_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Somali.pdf'
import free_tigrinya_pdf from '../pdf/USRAP_Free/USRAP_Process_is_Free_Tigrinya.pdf'

//USRAP Application
import US_APP_amharic_pdf from '../pdf/USRAP_Application/AmharicFlowChart.pdf'
import US_APP_arabic_pdf from '../pdf/USRAP_Application/ArabicFlowchart.pdf'
import US_APP_english_pdf from '../pdf/USRAP_Application/EnglishFlowChart.pdf'
import US_APP_francaise_pdf from '../pdf/USRAP_Application/FrenchFlowChart.pdf'
import US_APP_kinyarwanda_pdf from '../pdf/USRAP_Application/KinyarwandaFlowChart.pdf'
import US_APP_kirundi_pdf from '../pdf/USRAP_Application/KirundiFlowChart.pdf'
import US_APP_kiswahili_pdf from '../pdf/USRAP_Application/KiswahiliFlowChart.pdf'
import US_APP_oromo_pdf from '../pdf/USRAP_Application/OromoFlowChart.pdf'
import US_APP_somali_pdf from '../pdf/USRAP_Application/SomaliFlowChart.pdf'
import US_APP_tigrinya_pdf from '../pdf/USRAP_Application/TigrinyaFlowChart.pdf'

//P3 Application
import p3_amharic_pdf from '../pdf/P3/P3_Application_Process_Amharic.pdf'
import p3_arabic_pdf from '../pdf/P3/P3_Application_Process_Arabic.pdf'
import p3_english_pdf from '../pdf/P3/P3_Application_Process_English.pdf'
import p3_francaise_pdf from '../pdf/P3/P3_Application_Process_French.pdf'
import p3_kinyarwanda_pdf from '../pdf/P3/P3_Application_Process_Kinyarwanda.pdf'
import p3_kirundi_pdf from '../pdf/P3/P3_Application_Process_Kirundi.pdf'
import p3_kiswahili_pdf from '../pdf/P3/P3_Application_Process_Kiswahili.pdf'
import p3_oromo_pdf from '../pdf/P3/P3_Application_Process_Oromo.pdf'
import p3_somali_pdf from '../pdf/P3/P3_Application_Process_Somali.pdf'
import p3_tigrinya_pdf from '../pdf/P3/P3_Application_Process_Tigrinya.pdf'

//DNA Testing
import dna_amharic_pdf from '../pdf/DNA/DNA_Testing_Amharic.pdf'
import dna_arabic_pdf from '../pdf/DNA/DNA_Testing_Arabic.pdf'
import dna_english_pdf from '../pdf/DNA/DNA_Testing_English.pdf'
import dna_francaise_pdf from '../pdf/DNA/DNA_Testing_French.pdf'
import dna_kinyarwanda_pdf from '../pdf/DNA/DNA_Testing_Kinyarwanda.pdf'
import dna_kirundi_pdf from '../pdf/DNA/DNA_Testing_Kirundi.pdf'
import dna_kiswahili_pdf from '../pdf/DNA/DNA_Testing_Kiswahili.pdf'
import dna_oromo_pdf from '../pdf/DNA/DNA_Testing_Oromo.pdf'
import dna_somali_pdf from '../pdf/DNA/DNA_Testing_Somali.pdf'
import dna_tigrinya_pdf from '../pdf/DNA/DNA_Testing_Tigrinya.pdf'

//USRAP is Long
import long_amharic_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Amharic.pdf'
import long_arabic_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Arabic.pdf'
import long_english_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_English.pdf'
import long_francaise_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_French.pdf'
import long_kinyarwanda_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Kinyarwanda.pdf'
import long_kirundi_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Kirundi.pdf'
import long_kiswahili_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Kiswahili.pdf'
import long_oromo_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Oromo.pdf'
import long_somali_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Somali.pdf'
import long_tigrinya_pdf from '../pdf/USRAP_Long/The_Resettlement_Process_is_Long_Tigrinya.pdf'

//What can you do
import help_amharic_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_Amharic.pdf'
import help_english_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_English.pdf'
import help_francaise_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_French.pdf'
import help_kinyarwanda_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_Kinyarwanda.pdf'
import help_kirundi_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_Kirundi.pdf'
import help_kiswahili_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_Kiswahili.pdf'
import help_oromo_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_Oromo.pdf'
import help_somali_pdf from '../pdf/Help/What_you_Can_do_to_Help_Your_Case_Somali.pdf'

//Learning English
import learn_amharic_pdf from '../pdf/Learn/Learning_English_Amharic.pdf'
import learn_arabic_pdf from '../pdf/Learn/Learning_English_Arabic.pdf'
import learn_english_pdf from '../pdf/Learn/Learning_English_English.pdf'
import learn_francaise_pdf from '../pdf/Learn/Learning_English_French.pdf'
import learn_kinyarwanda_pdf from '../pdf/Learn/Learning_English_Kinyarwanda.pdf'
import learn_kirundi_pdf from '../pdf/Learn/Learning_English_Kirundi.pdf'
import learn_kiswahili_pdf from '../pdf/Learn/Learning_English_Kiswahili.pdf'
import learn_oromo_pdf from '../pdf/Learn/Learning_English_Oromo.pdf'
import learn_somali_pdf from '../pdf/Learn/Learning_English_Somali.pdf'
import learn_tigrinya_pdf from '../pdf/Learn/Learning_English_Tigrinya.pdf'

//Polygamy
import poly_amharic_pdf from '../pdf/Polygamy/Polygamy_Amharic.pdf'
import poly_arabic_pdf from '../pdf/Polygamy/Polygamy_Arabic.pdf'
import poly_english_pdf from '../pdf/Polygamy/Polygamy_English.pdf'
import poly_francaise_pdf from '../pdf/Polygamy/Polygamy_French.pdf'
import poly_kinyarwanda_pdf from '../pdf/Polygamy/Polygamy_Kinyarwanda.pdf'
import poly_kirundi_pdf from '../pdf/Polygamy/Polygamy_Kirundi.pdf'
import poly_kiswahili_pdf from '../pdf/Polygamy/Polygamy_Kiswahili.pdf'
import poly_oromo_pdf from '../pdf/Polygamy/Polygamy_Oromo.pdf'
import poly_somali_pdf from '../pdf/Polygamy/Polygamy_Somali.pdf'
import poly_tigrinya_pdf from '../pdf/Polygamy/Polygamy_Tigrinya.pdf'

//Preparing for Travel
import travel_amharic_pdf from '../pdf/Prepare/Amharic_Travel_Poster.pdf'
import travel_arabic_pdf from '../pdf/Prepare/Arabic_Travel_Poster.pdf'
import travel_english_pdf from '../pdf/Prepare/English_Travel_Poster.pdf'
import travel_francaise_pdf from '../pdf/Prepare/French_Travel_Poster.pdf'
import travel_kinyarwanda_pdf from '../pdf/Prepare/Kinyarwanda_Travel_Poster.pdf'
import travel_kirundi_pdf from '../pdf/Prepare/Kirundi_Travel_Poster.pdf'
import travel_kiswahili_pdf from '../pdf/Prepare/Kiswahili_Travel_Poster.pdf'
import travel_oromo_pdf from '../pdf/Prepare/Oromo_Travel_Poster.pdf'
import travel_somali_pdf from '../pdf/Prepare/Somali_Travel_Poster.pdf'

//Do's
import do_amharic_pdf from '../pdf/DO/Dos_and_Donts_Amharic.pdf'
import do_arabic_pdf from '../pdf/DO/Dos_and_Donts_Arabic.pdf'
import do_english_pdf from '../pdf/DO/Dos_and_Donts_English.pdf'
import do_francaise_pdf from '../pdf/DO/Dos_and_Donts_French.pdf'
import do_kinyarwanda_pdf from '../pdf/DO/Dos_and_Donts_Kinyarwanda.pdf'
import do_kirundi_pdf from '../pdf/DO/Dos_and_Donts_Kirundi.pdf'
import do_kiswahili_pdf from '../pdf/DO/Dos_and_Donts_Kiswahili.pdf'
import do_oromo_pdf from '../pdf/DO/Dos_and_Donts_Oromo.pdf'
import do_somali_pdf from '../pdf/DO/Dos_and_Donts_Somali.pdf'
import do_tigrinya_pdf from '../pdf/DO/Dos_and_Donts_Tigrinya.pdf'

//Expect
import pay_amharic_pdf from '../pdf/Expect/What_to_Expect_Amharic.pdf'
import pay_arabic_pdf from '../pdf/Expect/What_to_Expect_Arabic.pdf'
import pay_english_pdf from '../pdf/Expect/What_to_Expect_English.pdf'
import pay_francaise_pdf from '../pdf/Expect/What_to_Expect_French.pdf'
import pay_kinyarwanda_pdf from '../pdf/Expect/What_to_Expect_Kinyarwanda.pdf'
import pay_kirundi_pdf from '../pdf/Expect/What_to_Expect_Kirundi.pdf'
import pay_kiswahili_pdf from '../pdf/Expect/What_to_Expect_Kiswahili.pdf'
import pay_oromo_pdf from '../pdf/Expect/What_to_Expect_Oromo.pdf'
import pay_somali_pdf from '../pdf/Expect/What_to_Expect_Somali.pdf'
import pay_tigrinya_pdf from '../pdf/Expect/What_to_Expect_Tigrinya.pdf'

//Fraud
import fraud_amharic_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Amharic.pdf'
import fraud_arabic_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Arabic.pdf'
import fraud_english_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_English.pdf'
import fraud_francaise_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_French.pdf'
import fraud_kinyarwanda_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Kinyarwanda.pdf'
import fraud_kirundi_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Kirundi.pdf'
import fraud_kiswahili_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Kiswahili.pdf'
import fraud_oromo_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Oromo.pdf'
import fraud_somali_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Somali.pdf'
import fraud_tigrinya_pdf from '../pdf/Fraud/Fraud_Poster_Potrait_Tigrinya.pdf'

import { PdfResourceTemplate } from '../components/pdf_resource_template';
import { setMessages } from '../store/actions/messagesActions';
import { useDispatch } from 'react-redux';



    //fixtures to come from API
    var featureResource = {
        title: "USRAP is Free",
        img: app_free,
        pdfUrl: [free_amharic_pdf,free_arabic_pdf,free_english_pdf,free_francaise_pdf,free_kinyarwanda_pdf,free_kirundi_pdf,free_kiswahili_pdf,free_oromo_pdf,free_somali_pdf,free_tigrinya_pdf],
        languages: [ 'Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya'],
        body: 'Access to the United States Refugee Admissions Program is FREE. It is expressively forbidden for any RSC Africa employee or partner to accept money or any favors from refugees for any reason. If an RSC Africa employee, or anyone claiming to have access to RSC Africa, demands payment in exchange for their services, please report them immediately to Fraud@CWSAfrica.org'
    }

    //fixtures to come from API
    var pdResources = [
        {
            title: "USRAP Application Process",
            ftImage: us_reset_process,
            pdfUrl: [ US_APP_amharic_pdf, US_APP_arabic_pdf,US_APP_english_pdf,US_APP_francaise_pdf,US_APP_kinyarwanda_pdf,US_APP_kirundi_pdf,US_APP_kiswahili_pdf,US_APP_oromo_pdf,US_APP_somali_pdf,US_APP_tigrinya_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "P3 Application Process",
            ftImage: p3,
            pdfUrl: [p3_amharic_pdf,p3_arabic_pdf,p3_english_pdf,p3_francaise_pdf,p3_kinyarwanda_pdf,p3_kirundi_pdf,p3_kiswahili_pdf,p3_oromo_pdf,p3_somali_pdf,p3_tigrinya_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "DNA Testing is Easy",
            ftImage: dna,
            pdfUrl: [dna_amharic_pdf,dna_arabic_pdf,dna_english_pdf,dna_francaise_pdf,dna_kinyarwanda_pdf,dna_kirundi_pdf,dna_kiswahili_pdf,dna_oromo_pdf,dna_somali_pdf,dna_tigrinya_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "USRAP is Long",
            ftImage: patient,
            pdfUrl: [long_amharic_pdf,long_arabic_pdf,long_english_pdf,long_francaise_pdf,long_kinyarwanda_pdf,long_kirundi_pdf,long_kiswahili_pdf,long_oromo_pdf,long_somali_pdf,long_tigrinya_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "USRAP is Free",
            ftImage: fraud,
            pdfUrl: [fraud_amharic_pdf,fraud_arabic_pdf,fraud_english_pdf,fraud_francaise_pdf,fraud_kinyarwanda_pdf,fraud_kirundi_pdf,fraud_kiswahili_pdf,fraud_oromo_pdf,fraud_somali_pdf,fraud_tigrinya_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "What can you do to help RSC Africa with your case",
            ftImage: wat_can,
            pdfUrl: [help_amharic_pdf,help_english_pdf,help_francaise_pdf,help_kinyarwanda_pdf,help_kirundi_pdf,help_kiswahili_pdf,help_oromo_pdf,help_somali_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['Amharic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali']
        },
        {
            title: "Learning English Will Help You",
            ftImage: learn_eng,
            pdfUrl: [learn_amharic_pdf,learn_arabic_pdf,learn_english_pdf,learn_francaise_pdf,learn_kinyarwanda_pdf,learn_kirundi_pdf,learn_kiswahili_pdf,learn_oromo_pdf,learn_somali_pdf,learn_tigrinya_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "Polygamy",
            ftImage: spouse,
            pdfUrl: [poly_amharic_pdf,poly_arabic_pdf,poly_english_pdf,poly_francaise_pdf,poly_kinyarwanda_pdf,poly_kirundi_pdf,poly_kiswahili_pdf,poly_oromo_pdf,poly_somali_pdf,poly_tigrinya_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "Preparing For Travel",
            ftImage: prep,
            pdfUrl: [travel_amharic_pdf,travel_arabic_pdf,travel_english_pdf,travel_francaise_pdf,travel_kinyarwanda_pdf,travel_kirundi_pdf,travel_kiswahili_pdf,travel_oromo_pdf,travel_somali_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali']
        },
        {
            title: "Do's and Don'ts",
            ftImage: dos,
            pdfUrl: [do_amharic_pdf,do_arabic_pdf,do_english_pdf,do_francaise_pdf,do_kinyarwanda_pdf,do_kirundi_pdf,do_kiswahili_pdf,do_oromo_pdf,do_somali_pdf,do_tigrinya_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
        {
            title: "What To Expect",
            ftImage: pay,
            pdfUrl: [pay_amharic_pdf,pay_arabic_pdf,pay_english_pdf,pay_francaise_pdf,pay_kinyarwanda_pdf,pay_kirundi_pdf,pay_kiswahili_pdf,pay_oromo_pdf,pay_somali_pdf,pay_tigrinya_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['Amharic','Arabic','English', 'Francaise','Kinyarwanda','Kirundi','Kiswahili', 'Oromo', 'Somali', 'Tigrinya']
        },
    ]



const card = {
    margin: "50px",
    padding: "25px 140px 25px 120px"
}

const cardMobile = {
    margin: "15px",
    padding: "25px 25px 25px 25px"
}

const cardMedium = {
    margin: "50px",
    padding: "25px 120px 25px 120px"
}

const travel = {
    paddingTop: "25px"
}

//Reusable menu
export const LanguageMenu = ({ handleLanguageClick, languages, all_pdf_urls, handleLanguageChange }) => {
  
    return (
    
        <Menu onClick={handleLanguageClick} onChange={handleLanguageChange(all_pdf_urls)} >
            {languages.map(lang => (
                <Menu.Item key={lang}  > {lang}
                </Menu.Item>
            ))}
        </Menu>
    )
}

export const RefugeeResources = () => {

    const dispatch = useDispatch();

    //Varaibles to store api data
    const[featuredResource, setfeaturedResource] = useState(featureResource);
    const[pdfResources,setpdfResources] = useState(pdResources);
    const[messages,setMessages] = useState(data);

    //state to manage PDF display on mobile
    const [pagesCountMobile, setpagesCountMobile] = useState(1);
    const [pdfPerPageMobile, setPdfPerPageMobile] = useState(3);
    const [offsetMobile, setOffsetMobile] = useState(0);
    const [currentPagePdfMobile, setCurrentPagePdfMobile] = useState([])

    //state to manage PDF display on large screen
    const [pagesCountLarge, setpagesCountLarge] = useState(1);
    const [pdfPerPageLarge, setPdfPerPageLarge] = useState(9);
    const [offsetLarge, setOffsetLarge] = useState(0);
    const [currentPagePdfLarge, setCurrentPagePdfLarge] = useState([])

    //pdf to download state
    const [pdfToDownload, setPdfToDownLoad] = useState("");

    

    //pagination methods
    useEffect(() => {
        setPaginationStatesMobile();
        setPaginationStatesLarge();
    }, [])

    useEffect(() => {
        setPdfForCurrentPage();
        setPdfForCurrentPageLarge();
    }, [offsetMobile, offsetLarge])

    const handlePageClickMobile = (pageNumber) => {
        const currentPage = pageNumber - 1;
        const off = currentPage * pdfPerPageMobile;
        setOffsetMobile(off);
    }

    const handlePageClickLarge = (pageNumber) => {
        const currentPage = pageNumber - 1;
        const off = currentPage * pdfPerPageLarge;
        setOffsetLarge(off);
    }

    const [languageSelected, setLanguage] = useState('Select Language Download');
    const [pdfDownload, setDownload] = useState('');

    //handle click for languages on each single pdf
    const handleLanguageClick = ({ key }) => {
        setLanguage(key);
    }

    //handle change for languages on each single pdf
    const handleLanguageChange = (pdf_urls) => {
    
        let search_res;
        let pdf_to_down;
    
        for (var i = 0; i < pdf_urls.length; i++) {

            search_res = pdf_urls[i].toLowerCase().search(languageSelected.toLowerCase());

            if (search_res != -1) {
                pdf_to_down = pdf_urls[i];
                break;
            }
        }

        setPdfToDownLoad(pdf_to_down);
    }

    const resetLanguageState = () => {
        setLanguage("Select Language Download")
    }


    const [selectedVideo, setCurrentVideoSelected] = useState("item1")
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_medium = useMediaQuery(theme.breakpoints.up('sm'))
    const matches_large = useMediaQuery(theme.breakpoints.down('md'))
    const matches_extra = useMediaQuery('(max-width:480px)');

    // list of videos
    const video_list = [
        { title: 'video1', video_url: "" },
        { title: 'video2', video_url: "" },
        { title: 'video3', video_url: "" },
        { title: 'video4', video_url: "" }
    ];

    const onSelect = key => {
        setCurrentVideoSelected(key);
    }

    const videoMenu = (list, selected) =>

        list.map(el => <VideoScrollTemplate title={el.title} video_url={el.video_url} selected={selected} /> );

        const Arrow = ({ icon, className }) => {
            return (
                <div className={className}>{icon}</div>
            );
        };

    // const ArrowLeft = Arrow({ icon: <ArrowBackIosIcon style={{ fontSize: "52px" }} />, className: 'arrow-previous' });
    // const ArrowRight = Arrow({ icon: <ArrowForwardIosIcon style={{ fontSize: "52px" }} />, className: 'arrow-forward' });
    // const menu_vids = videoMenu(video_list, selectedVideo)

    //states for mobile view to be able to access pdfResources
    const [totalPdfCount, setTotalPdfCount] = useState(pdfResources.length);

    const setPdfForCurrentPage = () => {
        const currentPageMes = pdfResources.slice(offsetMobile, offsetMobile + pdfPerPageMobile);
        setCurrentPagePdfMobile(currentPageMes);
    }

    const setPdfForCurrentPageLarge = () => {
        const currentPageMes = pdfResources.slice(offsetLarge, offsetLarge + pdfPerPageLarge);
        setCurrentPagePdfLarge(currentPageMes);
    }

    const setPaginationStatesMobile = () => {
        setpagesCountMobile(Math.ceil(totalPdfCount / pdfPerPageMobile))
        setPdfForCurrentPage()
    }

    const setPaginationStatesLarge = () => {
        setpagesCountLarge(Math.ceil(totalPdfCount / pdfPerPageLarge))
        setPdfForCurrentPageLarge()
    }

    //api call

    const getFeaturedResource = ()=>{
        //ajax here
        //setfeaturedResource(res.data)
    }

    const getPdfResources = ()=>{
        //ajax here
        //setpdfdResources(res.data)
    }

    const getMessages = ()=>{
         //ajax here
        //setMessages(res.data)
        //update to store to be accessed globally 
        //dispatch(setMessages(res.data))
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getPdfResources();
        getFeaturedResource();
        getMessages();


    }, []);

    // Conditional Rendering for mobile screen, medium screen and large screen

    //Render mobile screen
    if (matches) {
        
        return (
            <>
                <Card style={cardMobile}>
                    <Row>
                        <Col span={24}>
                            <h1 className="fr-title-mobile">Featured Resource</h1>
                            <img src={featuredResource.img} className="fr-image" />
                        </Col>
                        <Col span={24}>
                            <p className="ft-title-right-mobile"> {featuredResource.title} </p>
                            <p>{featuredResource.body}</p>
                            
                            <Dropdown overlay={ 
                                <LanguageMenu 
                                    handleLanguageClick={handleLanguageClick} 
                                    handleLanguageChange={handleLanguageChange} 
                                    languages={featuredResource?.languages} 
                                    all_pdf_urls={featuredResource?.pdfUrl} />}>

                                <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                    {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ?  ('Select Language Download'):languageSelected} <DownOutlined />
                                </Button>
                            </Dropdown>
                            <br />
                            {
                                languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                Download <DownloadOutlined /></Button>)
                            }
                        </Col>
                    </Row>
                </Card>

                <div style={travel}>
                    <h1 className="fr-travel-title-mobile">PDF Resources</h1>
                    <Row style={{ backgroundColor: "#e8e8e8", marginTop: "30px", padding: "20px" }}>
                        {
                            currentPagePdfMobile.map((res) => (
                                <PdfResourceTemplate title={res.title} languages={res.languages} handleLanguageChange={handleLanguageChange} languageSelected={languageSelected} ftImage={res.ftImage} resetLanguageState={resetLanguageState} pdfToDownload={pdfToDownload} buttonColor={res.buttonColor} textColor={res.textColor} handleLanguageClick={handleLanguageClick} all_pdf_urls={res.pdfUrl} />
                            ))
                        }
                    </Row>
                    {
                        pagesCountMobile > 0 &&
                        <Pagination
                            style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                            defaultCurrent={1}
                            onChange={handlePageClickMobile}
                            size="small"
                            total={totalPdfCount}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Resources`}
                            pageSize={pdfPerPageMobile}
                            showSizeChanger={false}
                        />
                    }

                    <div style={{ backgroundColor: "#e8e8e8", padding: "20px" }}>
                        <div className='important-messages-section'>
                            <h1 className="fr-travel-title-mobile">Important Messages</h1>
                            <div className='messages-div'>
                                <Row>
                                    {messages.slice(0, 3).map((item, index) => (
                                        <MessagesTemplate title={item.title} date={item.date} body={item.body} id={item.id} />
                                    ))}
                                </Row>
                            </div>
                            <div className='view-more-div'><Link to='/important-messages'><button className='view-all-button'>VIEW ALL</button></Link></div>

                        </div>
                    </div>
                    {
                        matches_extra
                            ?
                            <div style={{ backgroundColor: "white", height: "600px" }}>
                                <Row>
                                    <Col span={24} style={{ padding: "30px", marginTop: "10px" }}>
                                        <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the Settle InApp to learn more about resettlement in the US</p>

                                        <Row>
                                            <img src={app_store} height="30px" width="70px" style={{ marginRight: "5px", objectFit: 'cover' }} />
                                            <img src={play_store} height="30px" width="70px" style={{ marginRight: "5px", objectFit: 'cover' }} />
                                            <img src={desktop} height="30px" width="70px" style={{ objectFit: 'cover' }} />
                                        </Row>
                                    </Col>
                                </Row>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <img src={settle} height="250px" width="200px" style={{ textAlign: "center" }} />
                                </Col>
                            </div>
                            :
                            <div style={{ backgroundColor: "white", height: "460px" }}>
                                <Row>
                                    <Col span={24} style={{ padding: "30px", marginTop: "10px" }}>
                                        <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the SettleIn App to learn more about resettlement in the US
                                        </p>

                                        <Row>
                                            <img src={app_store} height="30px" width="70px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                            <img src={play_store} height="30px" width="70px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                            <img src={desktop} height="38px" width="70px" sytle={{ objectFit: 'cover' }} />
                                        </Row>
                                    </Col>
                                </Row>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <img src={settle} height="250px" width="200px" style={{ textAlign: "center" }} />
                                </Col>
                            </div>
                    }
                </div>
            </>
        )

    }

    //Render Medium Screen
    if (matches_large && matches_medium) {
    
        return (
            <>
                <Card style={cardMedium}>
                    <Row>
                        <Col span={10}>
                            <h1 className="fr-title">Featured Resource</h1>
                            <img src={featuredResource.img} className="fr-image" />
                        </Col>
                        <Col span={4}></Col>
                        <Col span={10}>
                            <p className="ft-title-right-medium">{featuredResource.title}</p>
                            <p>{featuredResource.body}</p>

                            <Dropdown overlay={
                                <LanguageMenu 
                                    handleLanguageClick={handleLanguageClick} 
                                    handleLanguageChange={handleLanguageChange} 
                                    languages={featuredResource?.languages} 
                                    all_pdf_urls={featuredResource?.pdfUrl} />}>

                                <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                    {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ?  ('Select Language Download'):languageSelected} <DownOutlined />
                                </Button>
                            </Dropdown>
                            <br />
                            {
                                languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                    Download <DownloadOutlined /></Button>)
                            }
                        </Col>
                    </Row>
                </Card>

                <div style={travel}>
                    <h1 className="fr-travel-title">PDF Resources</h1>
                    <Row style={{ backgroundColor: "#e8e8e8", marginTop: "30px", padding: "20px" }}>
                    {
                    currentPagePdfMobile.map((res) => (
                        <PdfResourceTemplate title={res.title} languages={res.languages} handleLanguageChange={handleLanguageChange} languageSelected={languageSelected} ftImage={res.ftImage} resetLanguageState={resetLanguageState} pdfToDownload={pdfToDownload} buttonColor={res.buttonColor} textColor={res.textColor} handleLanguageClick={handleLanguageClick} all_pdf_urls={res.pdfUrl} />
                    ))
                    }
                    </Row>
          
                    {
                        pagesCountMobile > 0 &&
                        <Pagination
                            style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                            defaultCurrent={1}
                            onChange={handlePageClickMobile}
                            size="small"
                            total={totalPdfCount}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Resources`}
                            pageSize={pdfPerPageMobile}
                            showSizeChanger={false}
                        />
                    }

                    <div style={{ backgroundColor: "#e8e8e8", padding: "40px" }}>
                        <div className='important-messages-section'>
                            <h1 className="fr-travel-title">Important Messages</h1>
                            <div className='messages-div'>
                                <Row>
                                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10} >
                                        <h1 className="fr-title">Featured Resource</h1>
                                        <img src={featuredResource.img} className="fr-image" />
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10}>
                                        <p className="ft-title-right">{featuredResource.title}</p>
                                        <p>{featuredResource.body} </p>

                                        <Dropdown overlay={
                                            <LanguageMenu 
                                                handleLanguageClick={handleLanguageClick} 
                                                handleLanguageChange={handleLanguageChange} 
                                                languages={featuredResource?.languages} 
                                                all_pdf_urls={featuredResource?.pdfUrl} />}>

                                            <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                                {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('Select Language Download') : ("")}  <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                        <br />
                                        {
                                            languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                            Download <DownloadOutlined /></Button>)
                                        }
                                    </Col>
                                    <Col span={4}></Col>
                                </Row>
                            </div>

                            <div className='view-more-div'><Link to='/important-messages'><button className='view-all-button'>VIEW ALL</button></Link></div>
                        </div>
                    </div>
          
                    <div style={{ backgroundColor: "white", height: "400px" }}>
                        <Row>
                            <Col span={14} style={{ padding: "30px", marginTop: "20px" }}>
                                <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the SettleIn App to learn more about resettlement in the US</p>

                                <Row>
                                    <img src={app_store} height="50px" width="150px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                    <img src={play_store} height="50px" width="150px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                    <img src={desktop} height="55px" width="150px" style={{ objectFit: 'cover' }} />
                                </Row>
                            </Col>
                            <Col span={4} offset={2} style={{ textAlign: "center" }}>
                                <img src={settle} height="350px" width="350px" style={{ marginTop: "55px" }} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }

    //Otherwise render large screen
    return (
        <>
            <Card style={card}>
                <Row>
                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10} >
                        <h1 className="fr-title">Featured Resource</h1>
                        <img src={featuredResource.img} className="fr-image" />
                    </Col>
                    <Col span={1}></Col>
                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10}>
                        <p className="ft-title-right">{featuredResource.title}</p>
                        <p>{featuredResource.body} </p>

                        <Dropdown overlay={
                            <LanguageMenu 
                                handleLanguageClick={handleLanguageClick} 
                                handleLanguageChange={handleLanguageChange} 
                                languages={featuredResource?.languages} 
                                all_pdf_urls={featuredResource?.pdfUrl} />}>

                            <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ?  ('Select Language Download'):languageSelected} <DownOutlined />
                            </Button>
                        </Dropdown>
                        <br />
                        {
                            languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                Download <DownloadOutlined /></Button>)
                        }
                    </Col>
                    <Col span={4}></Col>
                </Row>
            </Card>
      
            <div style={travel}>
                <h1 className="fr-travel-title">PDF Resources</h1>
                <Row style={{ backgroundColor: "#e8e8e8", marginTop: "30px" }}>
                {
                    currentPagePdfLarge.map((res) => (
                        <>
                            <PdfResourceTemplate title={res.title} languages={res.languages} handleLanguageChange={handleLanguageChange} languageSelected={languageSelected} ftImage={res.ftImage} resetLanguageState={resetLanguageState} pdfToDownload={pdfToDownload} buttonColor={res.buttonColor} textColor={res.textColor} handleLanguageClick={handleLanguageClick} all_pdf_urls={res.pdfUrl} />
                        </>
                    ))
                }
                </Row>
                {
                    pagesCountLarge > 0 &&
                    <Pagination
                        style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                        defaultCurrent={1}
                        onChange={handlePageClickLarge}
                        size="small"
                        total={totalPdfCount}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Resources`}
                        pageSize={pdfPerPageLarge}
                        showSizeChanger={false}
                    />
                }

                <div style={{ backgroundColor: "#e8e8e8", padding: "20px" }}>
                    <div className='important-messages-section'>
                        <h1 className="fr-travel-title">Important Messages</h1>
                        <div className='messages-div'>
                            <Row>
                                {messages.slice(0, 3).map((item, index) => (
                                    <MessagesTemplate title={item.title} date={item.date} body={item.body} id={item.id} />
                                ))}
                            </Row>
                        </div>
                        <div className='view-more-div'><Link to='/important-messages'><button className='view-all-button'>VIEW ALL</button></Link></div>
                    </div>
                </div>

                <div style={{ height: "400px", backgroundColor: "white" }} id="bottom-div">
                    <Row>
                        <Col span={14} style={{ padding: "30px", marginTop: "20px" }}>
                            <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the SettleIn App to learn more about resettlement in the US</p>
              
                            <Row >
                                <img src={app_store} height="60px" width="140px" style={{ marginRight: "10px", marginTop: "5px", objectFit: 'cover' }} />
                                <img src={play_store} height="60px" width="140px" style={{ marginRight: "10px", marginTop: "5px", objectFit: 'cover' }} />
                                <img src={desktop} height="69px" width="140px" style={{ objectFit: 'cover' }} />
                            </Row>
                        </Col>
                        <Col span={2} offset={4}>
                            <img src={settle} height="360px" width="260px" style={{ marginTop: "60px", objectFit: 'cover' }} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
